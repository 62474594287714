








































































import { Component, Vue } from "vue-property-decorator";
import { setToken } from "@/services/Environment";
import { CloudNuggetService } from "@verticals/cloudnugget.frontend.js";
import { CloudNuggetAuthenticationService } from "@verticals/cloudnugget.frontend.js";
import { ENVIRONMENT } from "@/services/Config";
import { Inject } from "inversify-props";
import { IAccountClient, Register as RegisterCommand } from '@/services/CloudNuggetsApiClient';

@Component({
  components: {},
})
export default class Register extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;
  public confirmPassword: string = null;

  public credentials: RegisterCommand = new RegisterCommand();

  @Inject()
  private accountClient: IAccountClient;

  public async register() {
    this.isLoading = true;
    this.error = null;

    try {
      const cnservice: CloudNuggetAuthenticationService = new CloudNuggetAuthenticationService();

      // CloudNuggetService.SetEnvironment(ENVIRONMENT);
      let env = ENVIRONMENT;
      if (env === "develop") {
        env = "staging";
      }
      CloudNuggetService.SetEnvironment(env);

      if(!this.isValid) {
        return;
      }

      await this.accountClient.register(this.credentials);

      this.$root.$emit("register");

      if (this.$route.query.redirect_to) {
        const redirect = <string>this.$route.query.redirect_to;
        this.$router.push(redirect);
      } else {
        this.$router.push("/");
      }
    } catch (error) {
      console.error(error);
      if (error.response && 
        error.response.status === 400) {
        this.error = "Invalid username or password.";
      } else {
        this.error = "Internal server error.";
      }
    }
    this.isLoading = false;
  }

  public get isValid() {
    return !(
      !this.credentials.username ||
      !this.credentials.password ||
      (this.credentials.password != this.confirmPassword) ||
      this.credentials.password.length < 4
    );
  }
}
